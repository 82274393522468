import "../App.css";
import io from "socket.io-client";
import moment from "moment-timezone";
import logo from "../assets/logo.png";
import Blocked from "../components/Blocked";
import Carousel from "react-material-ui-carousel";
import MarketClose from "../components/MarketClose";
import { useEffect, useMemo, useState } from "react";
import bullionStats from "../assets/bullioStats.png";
import { commodityCalculation } from "../components/constants";
import EconomicNewsModal from "../components/EconomicNewsModal";
import SubscriptionExpired from "../components/SubscriptionExpired";
import { Box, LinearProgress, Modal, Typography } from "@mui/material";
import SubscriptionExpiringSoon from "../components/SubscriptionExpiringSoon";
import {
  allCommodities,
  allNewses,
  findBanners,
  getAdminProfile,
  getEconomicNews,
  getGoldPriceNews,
  getLiveValueTypeForDisplay,
  getRatio,
  getSocketUrl,
  getSpread,
} from "../sevice/home";

const { makeStyles } = require("@mui/styles");
let socket = localStorage.getItem("socketUrl")
  ? io(JSON.parse(localStorage.getItem("socketUrl")))
  : null;

const socket2 = io(process.env.REACT_APP_BACKEND_URL);
const adminId = process.env.REACT_APP_ADMIN_ID;

const useStyles = makeStyles((theme) => ({
  mainBody: {
    height: "100dvh",
    display: "flex",
    flexDirection: "column",
    background: "linear-gradient(180deg, #151F38 36.12%, #C6B25D 100%)",
  },
  mainContainer: {
    width: "100%",
    height: "90vh",
    margin: "auto",
    display: "grid",
    gridTemplateColumns: "1.4fr 2fr",
    gridTemplateRows: "1fr",
    gridTemplateAreas: `
        'leftPart rightPart'
      `,
    padding: "3rem 3rem 2rem 3rem",
    boxSizing: "border-box",
    columnGap: "2rem",
  },
  leftPart: {
    boxSizing: "border-box",
    gridArea: "leftPart",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  logo: {
    height: "30%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logoImg: {
    width: "50%",
    objectFit: "contain",
  },
  spotRate: {
    height: "47%",
    backgroundColor: "#0B101D",
    border: "1px solid #948B5C",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    gap: ".6rem",
    borderRadius: "10px",
    color: "#FFFFFF",
  },
  spotRateBoxGoldRowCol: {
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateValueGold: {
    border: "1px solid #948B5C",
    color: "#FFFFFF",
    borderRadius: "5px",
    width: "8.3vw",
    height: "7vh",
    display: "flex",
    alignItems: "center",
    transition: "background-color .5s ease",
  },
  spotRateValueSilver: {
    border: "1px solid #948B5C",
    color: "#FFFFFF",
    borderRadius: "5px",
    width: "8.3vw",
    height: "7vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color .5s ease",
  },
  heighAndLow: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    width: "8.8vw",
    justifyContent: "start",
  },
  stat: {
    height: "15%",
    backgroundColor: "#0B101D",
    borderRadius: "10px",
    border: "1px solid #948B5C",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "0px 20px 0px 20px",
  },
  bullionStatsImg: {
    width: "10.5vw",
    height: "auto",
  },
  rightPart: {
    gridArea: "rightPart",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    flexDirection: "column",
  },
  header: {
    height: "30%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  leenLogoImg: {
    width: "100%",
    alignItems: "center",
    height: "100%",
    objectFit: "contain",
  },

  commoditieTable: {
    height: "65.5%",
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    color: "#000000",
  },
  table: {
    gridArea: "table",
    gap: "26px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  tabeHeader: {
    backgroundColor: "#C3AD4B",
    flex: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "15%",
  },
  tableContent: {
    gridArea: "tableContent",
    display: "flex",
    height: "80%",
    flexDirection: "column",
    gap: "20px",
    borderRadius: "5px",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tableRowColumn: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    fontSize: "2vw",
  },
  tableRow1: {
    flexBasis: 0,
    minHeight: "15%",
    maxHeight: "15%",
    flexGrow: 1,
    gridArea: "tableRow1",
    backgroundColor: "#C3AD4B",
    display: "flex",
    boxShadow: "0px -15px 10px -5px rgba(0, 0, 0, 0.5)",
  },

  updates: {
    padding: "0rem 3rem 1.5rem 3rem",
    display: "flex",
    height: "7%",
    color: "#FFFFFF",
    maxWidth: "calc(100vw - 4rem)",
  },
  updatesHeader: {
    backgroundColor: "#0D1322",
    minWidth: "180px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px 0px 0px 5px",
  },
  updatesContent: {
    display: "flex",
    alignItems: "center",
    alignSelf: "center",
    backgroundColor: "#0D1322",
    height: "85%",
    flex: 1,
    borderRadius: "0px 30px 5px 0px",
  },

  blur: {
    filter: "blur(8px)",
  },
}));

const Home = () => {
  const classes = useStyles();
  const [adminData, setAdminData] = useState();
  const [openSubscriptionExpireSoon, setOpenSubscriptionExpireSoon] =
    useState(false);
  const [openSubscriptionExpierd, setOpenSubscriptionExpierd] = useState(false);
  const [openBlocked, setOpenBlocked] = useState(false);
  const [isMarketOpen, setIsMarketOpen] = useState(true);
  const [render, setRender] = useState(true);

  const [gold, setGold] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [silver, setSilver] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [ratio, setRatio] = useState({
    Buyers: "77%",
    chgValue: "+0.370%",
    commodity: "Gold",
  });
  const [displayBidOrBuy, setDisplayBidOrBuy] = useState({
    bidOrBuy: "Bid",
    askOrSell: "Ask",
  });
  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });
  const [commodities, setCommodities] = useState([]);
  const [banners, setBanners] = useState([]);
  const [news, setNews] = useState([]);
  const [goldNews, setGoldNews] = useState({});

  ////////////Block//////
  const handleOpenBlocked = () => setOpenBlocked(true);
  const handleCloseBlocked = () => {
    window.location.reload();
  };
  ///////////////////// SubscriptionExpierd /////////////////
  const handleOpenSubscriptionExpierd = () => setOpenSubscriptionExpierd(true);
  const handleCloseSubscriptionExpierd = () => window.location.reload();
  ///////////////////// SubscriptionExpierSoon /////////////////////
  const handleExpireSoonOpen = () => setOpenSubscriptionExpireSoon(true);
  const handleOpenExpireSoonClose = () => setOpenSubscriptionExpireSoon(false);

  ///////////////////// Check is Market Closed  /////////////////////
  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment().tz(adminData?.time_zone);
      const dayOfWeek = now.day();
      const hour = now.hour();
      console.log(dayOfWeek);
      if (
        (dayOfWeek >= 1 && dayOfWeek <= 5 && hour >= 1 && hour < 2) || // Monday to Friday, 1AM to 2AM
        (dayOfWeek === 6 && hour >= 1) || // Saturday, 1AM onwards
        dayOfWeek === 0 || // Sunday
        (dayOfWeek === 1 && hour < 2) // Monday, before 2AM
      ) {
        setIsMarketOpen(false);
      } else {
        setIsMarketOpen(true);
      }
    }, 1000); // Check every minute

    return () => clearInterval(interval);
  }, [adminData?.time_zone]);

  useMemo(() => {
    if (socket && render) {
      setRender(false);
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
      socket.on("gold-rate-change", (data) => {
        // setIsMarketOpen(data.data.isMarketOpen);
        setGold((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
      socket.on("silver-rate-change", (data) => {
        setSilver((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
    }
  }, [socket]);

  useEffect(() => {
    socket2.on("connect", () => {});
    socket2.on("disconnect", () => {});
    socket2.emit("join_room", { roomId: adminId });
    socket2.on("commodityRerender", (data) => {
      handleFindCommodities();
    });
    socket2.on("newsRerender", (data) => {
      hadlefetchNews();
    });
    socket2.on("spreadRerender", (data) => {
      handleFindSpread();
    });
    socket2.on("mediaRerender", (data) => {
      handelFindBanners();
    });
    socket2.on("adminBlockRerender", (data) => {
      window.location.reload();
    });
    socket2.on("socketRerender", (data) => {
      localStorage.removeItem("socketUrl");
      window.location.reload();
    });
    socket2.on("reloadRender", (data) => {
      window.location.reload();
    });
    socket2.on("upgradeRerender", (data) => {
      handleFindAdminProfile();
    });
    socket2.on("bidAndAskTitleRerender", (data) => {
      handleFindLiveValueTypeForDisplay();
    });
    socket2.on("economicNewsRender", (data) => {
      handleFindEconomicNews();
    });
  }, []);

  const handleFindCommodities = async () => {
    const res = await allCommodities();
    if (Array.isArray(res.commodities)) {
      setCommodities(res.commodities);
    }
  };
  const handleFindSpread = async () => {
    const res = await getSpread();
    if (res) {
      setSpread(res);
    }
  };
  const hadlefetchNews = async () => {
    const res = await allNewses();
    if (Array.isArray(res)) {
      setNews(res);
    }
  };
  const handleFindGoldPriceNews = async () => {
    const res = await getGoldPriceNews();
    if (res.length > 0) setGoldNews(res[0]);
  };

  const handelFindBanners = async () => {
    const res = await findBanners();
    if (Array.isArray(res)) {
      setBanners(res);
    }
  };
  const handleFindLiveValueTypeForDisplay = async () => {
    const res = await getLiveValueTypeForDisplay();
    if (res) {
      setDisplayBidOrBuy(res);
    }
  };
  const handleFindRatio = async () => {
    const res = await getRatio();
    if (res?.data) {
      setRatio(res.data);
    }
  };
  const handleFindSocketUrl = async () => {
    const res = await getSocketUrl();
    if (res) {
      socket = io(res?.socketURL);
      localStorage.setItem("socketUrl", JSON.stringify(res?.socketURL));
    }
  };

  const handleFindAdminProfile = async () => {
    const res = await getAdminProfile();
    setAdminData(res);
    if (!res.status || res.isBlocked) {
      handleOpenBlocked();
    } else if (!res.isBlocked && openBlocked) {
    } else {
      handlecheckSubscriptionExpierd(res);
    }
  };

  const handlecheckSubscriptionExpierd = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    let a = givenDate.toISOString();
    let b = new Date().toISOString();
    if (a < b) {
      handleOpenSubscriptionExpierd();
    }
  };

  useEffect(() => {
    if (adminData) {
      const interval = setInterval(() => {
        checkSubscriptionExpireSoon(adminData);
      }, 60 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [adminData]);

  const checkSubscriptionExpireSoon = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    const oneMonthAgo = new Date(givenDate);
    oneMonthAgo.setDate(givenDate.getDate() - 30);
    let a = oneMonthAgo.toISOString();
    let b = new Date().toISOString();
    if (b >= a) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour === 10) {
        handleExpireSoonOpen();
      }
    }
  };

  useEffect(() => {
    handleFindCommodities();
    handleFindSpread();
    hadlefetchNews();
    handelFindBanners();
    handleFindLiveValueTypeForDisplay();
    handleFindRatio();
    handleFindSocketUrl();
    handleFindAdminProfile();
    handleFindEconomicNews();
    handleFindGoldPriceNews();
  }, []);

  const [economicNews, setEconomicNews] = useState([]);
  const [openNews, setOpenNews] = useState(false);
  const handleOpenopenNews = () => setOpenNews(true);
  const handleCloseopenNews = () => setOpenNews(false);

  const [curEconomicNews, setCurEconomicNews] = useState([]);
  const handleFindEconomicNews = async () => {
    const res = await getEconomicNews();
    setEconomicNews(res);
  };

  useEffect(() => {
    const timeInterwell = setInterval(() => {
      economicNewsFinding();
    }, 1000 * 60 * 1);
    economicNewsFinding();
    return () => clearInterval(timeInterwell);
  }, [economicNews]);

  const economicNewsFinding = () => {
    if (economicNews.length) {
      const currentTime = new Date(new Date().toUTCString());
      const oneHourAgo = new Date(currentTime);
      oneHourAgo.setHours(currentTime.getHours() + 1);
      oneHourAgo.setSeconds(0);
      oneHourAgo.setMilliseconds(0);

      const currentTimeMin = new Date(new Date().toUTCString());
      const fiveMinAgo = new Date(currentTimeMin);
      fiveMinAgo.setMinutes(currentTimeMin.getMinutes());
      fiveMinAgo.setSeconds(0);
      fiveMinAgo.setMilliseconds(0);

      let objectBeforeFiveMin = economicNews.filter((obj) => {
        let utcDate = new Date(obj.date);
        let timeDiff =
          new Date(
            utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
          ).getTime() - fiveMinAgo.getTime();

        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff <= 5 && minutesDiff >= 0;
      });
      let objectBeforeOneHour = economicNews.filter((obj) => {
        let utcDate = new Date(obj.date);
        let timeDiff =
          new Date(
            utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
          ).getTime() - oneHourAgo.getTime();

        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff <= 5 && minutesDiff >= -5;
      });
      if (objectBeforeFiveMin.length) {
        objectBeforeFiveMin = objectBeforeFiveMin?.sort(
          (a, b) => b.priority - a.priority
        );
        setCurEconomicNews(objectBeforeFiveMin);
        handleOpenopenNews();
      } else if (objectBeforeOneHour.length) {
        objectBeforeOneHour = objectBeforeOneHour?.sort(
          (a, b) => b.priority - a.priority
        );
        setCurEconomicNews(objectBeforeOneHour);
        handleOpenopenNews();
      } else {
        setCurEconomicNews([]);
        handleCloseopenNews();
      }
    } else {
      setCurEconomicNews([]);
    }
  };
  const formatDate = () => {
    return moment(new Date()).format("DD MMM YYYY");
  };
  const formatday = () => {
    return moment(new Date()).format("dddd");
  };
  const formatTime = () => {
    return new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const progress = Number(ratio?.Buyers?.match(/\d+/)[0]);
  const buyersColor = "#3DA1EA";
  const sellersColor = "#EE3E3E";
  const progressStyle = { backgroundColor: sellersColor };
  const barStyle = { backgroundColor: buyersColor };

  return (
    <div
      style={{ height: "100dvh" }}
      className={(openSubscriptionExpierd || openBlocked) && classes.blur}
    >
      <Box className={classes.mainBody}>
        <Box className={classes.mainContainer}>
          {!isMarketOpen && <MarketClose timeZone={adminData?.time_zone} />}
          <Box className={classes.leftPart}>
            <Box className={classes.logo}>
              <img
                className={classes.logoImg}
                src={logo}
                style={{
                  alignItems: "center",
                  objectFit: "contain",
                }}
                alt="logo"
              />
            </Box>
            <Box className={classes.spotRate}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "20%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.3vw",
                    fontWeight: "bold",
                    flexBasis: 0,
                    flexGrow: 0.8,
                    flexShrink: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  SPOT RATE
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    flexBasis: 0,
                    flexGrow: 1,
                    flexShrink: 1,
                  }}
                >
                  <Typography
                    sx={{
                      backgroundColor: "#D1A235",
                      borderRadius: "3px",
                      px: "6px",
                    }}
                  >
                    $
                  </Typography>
                  <Typography sx={{ fontSize: "1.4vw", fontWeight: "bold" }}>
                    {displayBidOrBuy?.bidOrBuy?.toUpperCase()}
                  </Typography>
                  <Typography style={{ fontSize: "1.2vw" }}>oz</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    flexBasis: 0,
                    flexGrow: 1,
                    flexShrink: 1,
                  }}
                >
                  <Typography
                    sx={{
                      backgroundColor: "#D1A235",
                      borderRadius: "3px",
                      px: "6px",
                    }}
                  >
                    $
                  </Typography>
                  <Typography sx={{ fontSize: "1.4vw", fontWeight: "bold" }}>
                    &nbsp;
                    {displayBidOrBuy?.askOrSell?.toUpperCase()}
                  </Typography>
                  <Typography style={{ fontSize: "1.2vw" }}>oz</Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  height: "35%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.8vw",
                    fontWeight: "bold",
                    p: 0,
                    flexBasis: 0,
                    flexGrow: 0.8,
                    flexShrink: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  GOLD
                  <br />
                  <Typography
                    sx={{
                      fontSize: "1.8vw",
                      fontWeight: "bold",
                      flexBasis: 0,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: -1.5,
                    }}
                  >
                    &nbsp;
                  </Typography>
                </Typography>

                <Box className={classes.spotRateBoxGoldRowCol}>
                  <Box
                    className={classes.spotRateValueGold}
                    sx={{
                      backgroundColor:
                        Number(gold?.cur?.bid).toFixed(2) <
                        Number(gold?.pre?.bid).toFixed(2)
                          ? "#F63544"
                          : Number(gold?.cur?.bid).toFixed(2) >
                            Number(gold?.pre?.bid).toFixed(2)
                          ? "#3DA1EA"
                          : "#000000",
                      px: 1.5,
                    }}
                  >
                    <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                      {(!gold?.cur?.bid
                        ? 0
                        : Number(spread?.goldBidSpread) + Number(gold?.cur?.bid)
                      ).toFixed(2)}
                    </Typography>
                  </Box>

                  <Box className={classes.heighAndLow}>
                    <Typography
                      sx={{
                        fontSize: "1vw",
                        backgroundColor: "#F63544",
                        borderRadius: "3px",
                        px: 1,
                      }}
                    >
                      Low
                    </Typography>
                    &nbsp;
                    <Typography style={{ fontSize: "1vw" }}>
                      {(!gold?.cur?.bid
                        ? 0
                        : Number(spread.goldLowSpread) +
                          Number(gold?.cur?.lowPrice)
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>

                <Box className={classes.spotRateBoxGoldRowCol}>
                  <Box
                    className={classes.spotRateValueGold}
                    sx={{
                      backgroundColor:
                        Number(gold?.cur?.ask).toFixed(2) <
                        Number(gold?.pre?.ask).toFixed(2)
                          ? "#F63544"
                          : Number(gold?.cur?.ask).toFixed(2) >
                            Number(gold?.pre?.ask).toFixed(2)
                          ? "#3DA1EA"
                          : "#000000",
                      px: 1.5,
                    }}
                  >
                    <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                      {(!gold?.cur?.bid
                        ? 0
                        : Number(spread?.goldAskSpread) + Number(gold?.cur?.ask)
                      ).toFixed(2)}
                    </Typography>
                  </Box>

                  <Box className={classes.heighAndLow}>
                    <Typography
                      sx={{
                        fontSize: "1vw",
                        backgroundColor: "#3DA1EA",
                        borderRadius: "3px",
                        px: 1,
                      }}
                    >
                      High
                    </Typography>
                    &nbsp;
                    <Typography style={{ fontSize: "1vw" }}>
                      {(!gold?.cur?.bid
                        ? 0
                        : Number(spread?.goldHighSpread) +
                          Number(gold?.cur?.highPrice)
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  height: "35%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.4vw",
                    flexBasis: 0,
                    flexGrow: 0.8,
                    flexShrink: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  SILVER
                  <Typography
                    sx={{
                      fontSize: "1.4vw",
                      flexBasis: 0,
                      display: "flex",
                      justifyContent: "center",
                      marginTop: -1,
                    }}
                  >
                    &nbsp;
                  </Typography>
                </Typography>

                <Box className={classes.spotRateBoxGoldRowCol}>
                  <Box
                    className={classes.spotRateValueSilver}
                    sx={{
                      backgroundColor:
                        Number(silver?.cur?.bid).toFixed(3) <
                        Number(silver?.pre?.bid).toFixed(3)
                          ? "#F63544"
                          : Number(silver?.cur?.bid).toFixed(3) >
                            Number(silver?.pre?.bid).toFixed(3)
                          ? "#3DA1EA"
                          : "#000000",
                      px: 1.5,
                    }}
                  >
                    <Typography sx={{ fontSize: "2vw" }}>
                      &nbsp;&nbsp;
                      {(!gold?.cur?.bid
                        ? 0
                        : Number(spread?.silverBidSpread) +
                          Number(silver?.cur?.bid)
                      ).toFixed(3)}
                      &nbsp;&nbsp;
                    </Typography>
                  </Box>

                  <Box className={classes.heighAndLow}>
                    <Typography
                      sx={{
                        fontSize: "1vw",
                        backgroundColor: "#F63544",
                        borderRadius: "3px",
                        px: 1,
                      }}
                    >
                      Low
                    </Typography>
                    &nbsp;
                    <Typography style={{ fontSize: "1vw" }}>
                      {(!gold?.cur?.bid
                        ? 0
                        : Number(spread.silverLowSpread) +
                          Number(silver?.cur?.lowPrice)
                      ).toFixed(3)}
                    </Typography>
                  </Box>
                </Box>

                <Box className={classes.spotRateBoxGoldRowCol}>
                  <Box
                    className={classes.spotRateValueSilver}
                    sx={{
                      backgroundColor:
                        Number(silver?.cur?.ask).toFixed(3) <
                        Number(silver?.pre?.ask).toFixed(3)
                          ? "#F63544"
                          : Number(silver?.cur?.ask).toFixed(3) >
                            Number(silver?.pre?.ask).toFixed(3)
                          ? "#3DA1EA"
                          : "#000000",
                      px: 1.5,
                    }}
                  >
                    <Typography sx={{ fontSize: "2vw" }}>
                      &nbsp;&nbsp;
                      {(!gold?.cur?.bid
                        ? 0
                        : Number(spread?.silverAskSpread) +
                          Number(silver?.cur?.ask)
                      ).toFixed(3)}
                      &nbsp;&nbsp;
                    </Typography>
                  </Box>

                  <Box className={classes.heighAndLow}>
                    <Typography
                      sx={{
                        fontSize: "1vw",
                        backgroundColor: "#3DA1EA",
                        borderRadius: "3px",
                        px: 1,
                      }}
                    >
                      High
                    </Typography>
                    &nbsp;
                    <Typography style={{ fontSize: "1vw" }}>
                      {(!gold?.cur?.bid
                        ? 0
                        : Number(spread?.silverHighSpread) +
                          Number(silver?.cur?.highPrice)
                      ).toFixed(3)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className={classes.stat}>
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    px: 5,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography sx={{ fontSize: "1.1vw" }}>BUYERS</Typography>
                  <Typography
                    sx={{
                      fontSize: "1.1vw",
                      color:
                        Number(ratio?.chgValue.replace("%", "")) >= 0
                          ? "#3DA1EA"
                          : "#F63544",
                    }}
                  >
                    {ratio?.chgValue}
                  </Typography>
                  <Typography sx={{ fontSize: "1.1vw" }}>SELLERS</Typography>
                </Box>
                <Box sx={{ width: "90%", px: 4 }}>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    style={progressStyle}
                    sx={{
                      "& .MuiLinearProgress-bar": barStyle,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    px: 7,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography sx={{ fontSize: "1.1vw", color: "#3DA1EA" }}>
                    {ratio?.Buyers}
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    <a href="https://www.bullionstats.com/">
                      <img
                        className={classes.bullionStatsImg}
                        src={bullionStats}
                        alt="bullionStats"
                      />
                    </a>
                  </Box>
                  <Typography sx={{ fontSize: "1.1vw", color: "#F63544" }}>
                    {100 - Number(ratio?.Buyers?.match(/\d+/)[0])}%
                  </Typography>
                </Box>
              </>
            </Box>
          </Box>
          <Box className={classes.rightPart}>
            <Box className={classes.header}>
              <Box sx={{ width: "60%", height: "100%" }}>
                <Carousel
                  animation="fade" // Set the animation type to slide
                  navButtonsAlwaysVisible={false} // Show navigation buttons always
                  interval={10000}
                  indicatorContainerProps={{
                    style: {
                      display: "none",
                    },
                  }}
                  sx={{
                    height: "100%",
                  }}
                  transitionTime={3000} // Adjust the transition time to control the speed of the fade effect
                  autoPlay
                  duration={2000}
                >
                  {banners?.map((banner, i) => (
                    <img
                      style={{
                        width: "100%",
                        height: "24vh",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                      src={banner.imageUrl}
                      alt="banner"
                    />
                  ))}
                </Carousel>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  color: "#fff",
                  alignItems: "end",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "2.5vw",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    {formatTime()
                      .toUpperCase()
                      .split("")
                      .map((letter, index) => (
                        <span key={index}>{letter}</span>
                      ))}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.9vw",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    {formatday()
                      .toUpperCase()
                      .split("")
                      .map((letter, index) => (
                        <span key={index}>{letter}</span>
                      ))}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.8vw",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    {formatDate()
                      .toUpperCase()
                      .split("")
                      .map((letter, index) => (
                        <span key={index}>{letter}</span>
                      ))}
                  </Typography>{" "}
                </Box>
              </Box>
            </Box>

            <Box className={classes.commoditieTable}>
              <Box className={classes.table}>
                <Box className={classes.tabeHeader}>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "start",
                      paddingLeft: "2vw",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    COMMODITY
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    WEIGHT
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    {displayBidOrBuy?.askOrSell?.toUpperCase()}
                    <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                      &nbsp;AED
                    </Typography>
                  </Typography>
                </Box>

                <Box className={classes.tableContent}>
                  {commodities?.map((commodity, idx) => {
                    const words = commodity.commodity_title.split(" ");
                    return (
                      <Box
                        key={idx}
                        className={classes.tableRow1}
                        sx={{
                          maxHeight: commodities.length > 6 ? "17%" : "",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "1.3vw",
                            fontWeight: "bold",
                            justifyContent: "start",
                            paddingLeft: "2vw",
                          }}
                          className={classes.tableRowColumn}
                        >
                          {words?.map((word, index) => {
                            if (index === 0) {
                              return (
                                <span
                                  key={index}
                                  style={{
                                    fontSize: "2vw",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {word.toUpperCase()}
                                </span>
                              );
                            } else {
                              return (
                                <span
                                  key={index}
                                  style={{
                                    fontSize: "1vw",
                                    marginTop: "0.2rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  &nbsp;{word.toUpperCase()}
                                </span>
                              );
                            }
                          })}
                          &nbsp;
                          {commodity.unitLabel === "TTB"
                            ? ""
                            : commodity.displayPurity}
                        </Typography>
                        <Box
                          className={classes.tableRowColumn}
                          sx={{
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "2vw", fontWeight: "bold" }}
                            sx={{}}
                          >
                            {commodity.unit} {commodity.unitLabel}
                          </Typography>
                        </Box>
                        <Box
                          className={classes.tableRowColumn}
                          sx={{
                            justifyContent: "left",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "2vw", fontWeight: "bold" }}
                            sx={{
                              paddingLeft: { lg: "5.5vw", md: "5vw" },
                            }}
                          >
                            {!gold?.cur?.bid
                              ? "0.00"
                              : commodityCalculation(
                                  commodity.commodity_title === "Silver"
                                    ? silver?.cur?.ask
                                    : gold?.cur?.ask,
                                  commodity.commodity_title === "Silver"
                                    ? spread.silverAskSpread
                                    : spread.goldAskSpread,
                                  commodity.premium,
                                  3.674,
                                  commodity.purity,
                                  commodity.unit,
                                  commodity.unitLabel === "TTB"
                                    ? 116.64
                                    : commodity.unitLabel === "KG"
                                    ? 1000
                                    : commodity.unitLabel === "OZ"
                                    ? 31.1
                                    : commodity.unitLabel === "TOLA"
                                    ? 11.7
                                    : 1,
                                  commodity.charges
                                )}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.updates}>
          <Box className={classes.updatesHeader}>
            <Typography
              sx={{
                fontSize: "1.2rem",
                fontWeight: "bold",
                textAlign: "center",
                mt: "8px",
                mb: "5px",
              }}
            >
              Updates
            </Typography>
          </Box>
          <Box className={classes.updatesContent}>
            <marquee
              style={{
                height: "60%",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex" }}>
                {!adminData?.isNewsEnable &&
                Object.keys(goldNews).length > 0 ? (
                  <Box
                    style={{
                      fontSize: "1.5vw",
                      fontFamily: "poppins",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Gold Price News</span>
                    &nbsp;&nbsp;:&nbsp;&nbsp;<span>{goldNews?.title}</span>
                  </Box>
                ) : (
                  news?.map((item, index) => {
                    return (
                      <Box
                        key={item._id}
                        style={{
                          fontSize: "1.5vw",
                          fontFamily: "poppins",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {item.newsTitle}
                        </span>
                        &nbsp;&nbsp;:&nbsp;&nbsp;<span>{item.newsDetails}</span>
                        <span
                          style={{ paddingRight: "50px", paddingLeft: "50px" }}
                        >
                          {index === news.length - 1 ? "" : "|"}
                        </span>
                      </Box>
                    );
                  })
                )}
              </Box>
            </marquee>
          </Box>
        </Box>
      </Box>
      <Modal open={openBlocked} closeAfterTransition>
        <Blocked handleClose={handleCloseBlocked} />
      </Modal>
      <Modal open={openSubscriptionExpierd} closeAfterTransition>
        <SubscriptionExpired handleClose={handleCloseSubscriptionExpierd} />
      </Modal>
      <Modal
        open={openSubscriptionExpireSoon}
        onClose={handleOpenExpireSoonClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.1)", // Adjust the color and opacity as needed
            backdropFilter: "none", // This disables the blur effect
          },
        }}
      >
        <SubscriptionExpiringSoon
          date={adminData?.package.expire_date}
          handleClose={handleOpenExpireSoonClose}
        />
      </Modal>
      <Modal
        open={openNews}
        onClose={handleCloseopenNews}
        closeAfterTransition
        hideBackdrop
      >
        <EconomicNewsModal
          handleClose={handleCloseopenNews}
          curEconomicNews={curEconomicNews}
        />
      </Modal>
    </div>
  );
};

export default Home;
